// let windowWidth;

const handleMobileMenu = () => {
  windowWidth = window.innerWidth;

  if (windowWidth <= 960) {
    $("#nav").on("click", function (e) {
      link = e.target.closest("a");
      console.log(link);
    });
  } else {
    return;
  }
};

$(document).ready(function () {
  var hamburger = document.getElementById("hamburger");
  var icon1 = document.getElementById("a");
  var icon2 = document.getElementById("b");
  var icon3 = document.getElementById("c");

  hamburger.addEventListener("click", function () {
    icon1.classList.toggle("a");
    icon2.classList.toggle("c");
    icon3.classList.toggle("b");

    $("#nav").toggleClass("opened");
    $("#hamburger").toggleClass("active");
  });

  handleMobileMenu();
});

$(window).resize(function () {
  handleMobileMenu();
});

// $(document).click(function (event) {
//   var $target = $(event.target);
//   if (
//     !$target.closest("#menucontainer").length &&
//     $("#menucontainer").is(":visible")
//   ) {
//     $("#menucontainer").hide();
//   }
// });
